import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#A0C5C0',
        secondary: '#3F5465',
        tertiary: '#FFA500',
      },
    },
  },
})
