import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    certifications: require('@/data/certificaciones.json'),
    cocteles: require('@/data/cocteles.json'),
    events: require('@/data/events.json'),
    language: require('@/data/languages.json'),
    links: require('@/data/links.json'),
    medallas: require('@/data/medallas.json'),
    mezcalstory: require('@/data/mezcalstory.json'),
    notascata: require('@/data/notascata.json'),
    products: require('@/data/products.json'),
    webpagecontent: require('@/data/webpage.json'),
    backendUrl: 'https://mezcalpopolvuh.com.mx/popolvuh-backend/web/',
    drawer: false,
    lang: 'es',
    ofAge: false,
    showModal: false,
  },
  getters: {
    categories: state => {
      const categories = []

      for (const article of state.articles) {
        if (
          !article.category ||
          categories.find(category => category.text === article.category)
        ) continue

        const text = article.category

        categories.push({
          text,
          href: '#!',
        })
      }

      return categories.sort().slice(0, 4)
    },
    links: (state, getters) => {
      return state.links.items[state.lang]
    },
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: state => (state.drawer = !state.drawer),
    ofAge: state => (state.ofAge = !state.ofAge),
    showModal: state => (state.showModal = !state.showModal),
    setLang: (state, payload) => (state.lang = payload),
  },
  actions: {
  },
})
