import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import VueAnalytics from 'vue-ua'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/qr',
      name: 'qr',
      component: Home,
    },
    {
      path: "*",
      component: () => import("../views/NotFound.vue")
    },
  ],
})

Vue.use(VueAnalytics, {
  appName: 'Popolvuh',
  appVersion: '1.0',
  trackingId: 'UA-168764134-1',
  vueRouter: router,
})

export default router
