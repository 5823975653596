<template>
  <v-app
    :class="ofAge"
  >
    <core-view />
  </v-app>
</template>

<script>
  export default {
    name: 'App',
    components: {
      CoreView: () => import('@/components/core/View'),
    },
    computed: {
      ofAge: {
        get () {
          if (this.$store.state.ofAge) {
            return ''
          } else {
            return 'age-background'
          }
        },
      },
    },
  }
</script>

<style scoped>
  .age-background {
    background-color: #3F5465;
  }
</style>
